<template>
  <div class="grid grid-cols-1 md:grid-cols-2">
    <router-link :to="localizedRoute(`/blog/${post.slug}/`)" class="block h-72">
      <img :src="featuredImage" :alt="post.title + ' image'" class="object-cover h-full">
    </router-link>
    <div class="p-5 bg-white">
      <router-link :to="localizedRoute(`/blog/${post.slug}/`)">
        <h3 class="font-bold text-lg mt-0 mb-1">
          {{ post.title }}
        </h3>
      </router-link>
      <div class="text-base">
        {{ excerpt }}
      </div>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

function truncate (text, length, suffix) {
  return text.length > 0 ? text.substring(0, length) + suffix : ''
}

function prettyDate (date, storeView) {
  const _storeView = storeView || currentStoreView();

  let newDate = new Date(date);
  let dateFormat = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  return newDate.toLocaleDateString(_storeView.i18n.defaultLocale, dateFormat);
}

export default {
  name: 'PostItemHorizontal',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    featuredImage () {
      const img = this.post.blogFeaturedImage;
      return img.length ? img[0].url : '';
    },
    formattedDate () {
      return prettyDate(this.post.date);
    },
    excerpt () {
      return truncate(this.post.summary, 150, '...');
    }
  }
}
</script>
